import React from 'react'
import { useInView } from 'react-intersection-observer';
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import Portfolio from './components/portfolio/Portfolio'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import { useState, useEffect } from 'react';

const App = () => {
  const { ref, inView } = useInView();

  const { ref: ref1, inView: inView1 } = useInView();

  const { ref: ref2, inView: inView2 } = useInView();

  const { ref: ref3, inView: inView3 } = useInView();

  const { ref: ref4, inView: inView4 } = useInView();

  const [activeElement, setActiveElement] = useState('#')

  useEffect(() => {
    if (inView) {
      setActiveElement('#home');
    };
    if (inView1) {
      setActiveElement('#about')
    };
    if (inView2) {
      setActiveElement('#experience')
    };
    if (inView3) {
      setActiveElement('#services')
    };
    if (inView4) {
      setActiveElement('#contact')
    };
  }, [inView, inView1, inView2, inView3, inView4])
  
  return (
    <>
      <Header innerRef={ref} />
      <Nav activeElement={activeElement}/>
      <About innerRef={ref1} />
      <Experience innerRef={ref2} />
      <Services innerRef={ref3} />
      <Portfolio />
      <Contact innerRef={ref4} />
      <Footer />
    </>
  )
}

export default App