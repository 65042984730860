import React from 'react'
import './header.css'
import CTA from './CTA'
import Me from '../../assets/Edmund_Outline_Hover.png'
import HeaderSocials from './HeaderSocials'

const Header = ({ innerRef }) => {
  return (
    <header id="home">
      <div className="container header__container" ref={innerRef}>
        <h5>Hello, I am</h5>
        <h1 className="header-name">Edmund Weir</h1>
        <h3 className="header-title">Lead Developer at <a className='header-title-link' href="https://doe.games" target="_blank" rel="noreferrer noopener">Descendants of Earth</a></h3>
        <h3 className='header-subtitle'>& occasional <a className='header-subtitle-link' href="#contact">Bounty Hunter</a>.</h3>
        <CTA />
        <HeaderSocials />

        <div className="me">
          <img className="header__img" src={Me} alt="Edmund" />
        </div>

        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header