import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/DoE_Generator.png'
import IMG2 from '../../assets/Login_1920x1080.png'
import IMG3 from '../../assets/DoP_Cover.png'
import IMG4 from '../../assets/CodeSnippet.PNG'
import IMG5 from '../../assets/AuthorWebsite.PNG'
import IMG6 from '../../assets/BushFlyover.png'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Mobile Game Development',
    demo: 'https://doe.games'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Advanced Interface Design',
    demo: 'https://doe.games'
    
  },
  {
    id: 3,
    image: IMG3,
    title: 'Book Publishing & Cover Art',
    demo: 'https://www.amazon.com/Descendants-Power-Dystopian-Political-Thriller-ebook/dp/B08RCCN9J8/ref=sr_1_1?keywords=descendants+of+power&qid=1656907602&sr=8-1'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Software Development',
    demo: 'https://github.com/edmundweir'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Web Design & Hosting',
    demo: 'https://pnshafa.com'
  },
  {
    id: 6,
    image: IMG6,
    title: '3D Environment Modelling',
    demo: 'https://github.com/edmundweir'
  }
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="portfolio__container">
        {
          data.map(({id, image, title, demo}) => {
            return (        
              <article key={id} className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={demo} className='button' target="_blank" rel="noreferrer noopener">Demo</a>
                </div>
              </article>
            )
          })
        }
      </div> 
    </section>
  )
}

export default Portfolio