import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = ({ innerRef }) => {
  return (
    <section id='services' className="services__section" ref={innerRef}>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="services__container">
        <article className="service">
          <div className="service__head">
            <h3>Game Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>UI/UX design specialist (2D & 3D)</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Tailored scripting for Unity 3D</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Authentication & cloud storage</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Procedural map generation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>App store publishing (iOS & Android)</p>
            </li>
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Bespoke design solutions</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Dynamic web components with React</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Direct contact options</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Company-scale database management</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
        <article className="service">
          <div className="service__head">
            <h3>3D Rendering</h3>
          </div>
          <ul className="service__list">
          <li>
              <BiCheck className='service__list-icon'/>
              <p>Building or environment fly-throughs</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Realistic terrain & vegetation rendering</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Map to model (2D to 3D) conversions</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Character creation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Seasonal weather effects</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services