import React, { useRef } from 'react'
import './contact.css'
import {MdOutlineMail} from 'react-icons/md'
import {RiMessengerFill} from 'react-icons/ri'
import emailjs from 'emailjs-com'

const Contact = ({ innerRef }) => {
  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7civi6c', 'template_5e2gusd', form.current, 'gHMJoQbjy-SDARz75')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    e.target.reset();
  }
  
  return (
    <section id='contact' ref={innerRef}>
      <h5>Get In Touch</h5>
      <h2>Contact</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>edmund.weir@proton.me</h5>
            <a href="mailto:email@email.com" target="_blank" rel="noreferrer noopener" >Send a message</a>
          </article>
          <article className="contact__option">
            <RiMessengerFill className='contact__option-icon' />
            <h4>Messenger</h4>
            <a href="https://m.me/edmund1" target="_blank" rel="noreferrer noopener">Send a message</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Full Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea type="message" name="message" rows="7" placeholder="Your Message" required />
          <button type='submit' className="button button-primary" >Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact