import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {BsTwitter, BsGithub} from 'react-icons/bs'
import {AiFillLinkedin} from 'react-icons/ai'
import {SiMinds, SiInstagram} from 'react-icons/si'

const Footer = () => {
  return (
    <footer>
      <a href="#home" className="footer__logo">Edmund Weir</a>

      <ul className='permalinks'>
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://facebook.com/edmund1" target="_blank" rel="noreferrer noopener"><FaFacebookF /></a>
        <a href="https://www.instagram.com/descendantsofearth/" target="_blank" rel="noreferrer noopener"><SiInstagram /></a>
        <a href="https://twitter.com/DescendantEarth" target="_blank" rel="noreferrer noopener"><BsTwitter /></a>
        <a href="https://www.linkedin.com/in/edmundweir/" target="_blank" rel="noreferrer noopener"><AiFillLinkedin /></a>
        <a href="https://github.com/edmundweir" target="_blank" rel="noreferrer noopener"><BsGithub /></a>
        <a href="https://minds.com/a_journey_with_no_endmund" target="_blank" rel="noreferrer noopener"><SiMinds /></a>
      </div>

      <div className="footer__copyright">
        <small>
          &copy; Edmund Weir. All rights reserved. Background art courtesy of <a href="https://favpng.com/png_user/Audreyanon/1" target="_blank" rel="noreferrer noopener">Audrey</a>.
        </small>
      </div>
      <div className="footer__credit">
        <small>
        <small>
           
        </small>
        </small>
      </div>
    </footer>
  )
}

export default Footer