//this component is for a Call To Action
import React from 'react'
import CV from '../../assets/CV_Edmund.pdf'

const CTA = () => {
  return (
    <div className='cta'>
        <a href={CV} download='CV_EdmundWeir' target="_blank" className='button' rel="noreferrer noopener">Download CV</a>
        <a href="#contact" className='button button-primary'>Let's Talk</a>
    </div>
  )
}

export default CTA