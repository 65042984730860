import React from 'react'
import './About.css'
import Me from '../../assets/Edmund_And_Rumi.jpg'
import {FaAward, FaCode} from 'react-icons/fa' 
import {RiPlantFill} from 'react-icons/ri' 

const About = ({ innerRef }) => {
  return (
    <section id='about' ref={innerRef}>
      <h5>Learn A Little</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={Me} alt="About Me" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaCode className='about__icon'/>
              <h5>Experience</h5>
              <small>Unity 3D Developer with</small>
              <br />
              <small>5+ Years Programming</small>
            </article>

            <article className="about__card">
              <FaAward className='about__icon'/>
              <h5>Qualifications</h5>
              <small>Masters of Urban Planning</small>
              <br />
              <small>Bachelor of Environments</small>
            </article>

            <article className="about__card">
              <RiPlantFill className='about__icon'/>
              <h5>Indie Publishing</h5>
              <small>From concept to release.</small>
            </article>
          </div>
          <p>Edmund is a mobile developer based in Melbourne, Australia.  He studied environmental sciences & design in his Bachelor of Environments degree, and received his Masters of Urban Planning at the University of Melbourne, before entering the workforce as an urban planner. A creative and a technician at heart, Edmund made the switch to game development full-time in 2019 with a focus on urban and ecological systems.</p>
          <p>Edmund specialises in mobile game development with C# and Unity, as well as cloud storage with Google Firebase. He also has web development skills with React, HTML5, CSS and Javascript. He is a self-taught and full-stack Android and Apple developer. He enjoys long walks on the beach and how the limitations of mobile development aid in building more efficient software.</p> 
          <p>Together with his Co-Founder at Native Humanoid, <a href="https://pnshafa.com" target="_blank" rel="noreferrer noopener">Natalia Shafa</a>, he has helped to edit and publish their first novel, <a href="https://www.amazon.com.au/Descendants-Power-Dystopian-Political-Thriller-ebook/dp/B08RCCN9J8" target="_blank" rel="noreferrer noopener">Descendants of Power</a>, which was a bestseller on Amazon. The pair are now funded for the studio's first major game release in 2024 with <a href="https://descendantsofearth.com" target="_blank" rel="noreferrer noopener">Descendants of Earth</a>, which is the first mobile game in the world to use real environmental action to affect a 3D game world.</p>
          <a href="#contact" className='button button-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About
