import React from 'react'
import {BsLinkedin, BsGithub, BsInstagram, BsTwitter} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/edmundweir/" target="_blank" rel="noreferrer noopener"><BsLinkedin /></a>
        <a href="https://github.com/edmundweir" target="_blank" rel="noreferrer noopener"><BsGithub /></a>
        <a href="https://www.instagram.com/descendantsofearth/" target="_blank" rel="noreferrer noopener"><BsInstagram /></a>
        <a href="https://twitter.com/DescendantEarth" target="_blank" rel="noreferrer noopener"><BsTwitter /></a>
    </div>
  )
}

export default HeaderSocials